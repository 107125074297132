import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../runtime-context';
import SiteLink from './site-link';
import NoopLink from './noop-link';
import { getProfileLinkTemplate } from '../../store/ma-navigation/ma-navigation-selectors';
import { buildLink } from '../../store/ma-navigation/build-link';
import forMembersArea from '../../hoc/for-members-area';
import forUserPrivacyType from '../../hoc/for-user-privacy-type';

const handleClick = (navigateToProfile, user) => (event) => {
  event.preventDefault();
  navigateToProfile(user.siteMemberId, user.slug);
};

const ProfileLink = ({ user, profileLinkTemplate, navigateToProfile, isNavigationAllowed, ...props }) => {
  if (typeof window !== 'undefined' && window.__navigateWithinBlogInternally && isNavigationAllowed) {
    props.onClick = handleClick(navigateToProfile, user);
  }
  return <SiteLink to={buildLink(profileLinkTemplate, user.siteMemberId)} {...props} data-hook={'profile-link'} />;
};

ProfileLink.propTypes = {
  user: PropTypes.object.isRequired,
  profileLinkTemplate: PropTypes.string,
  navigateToProfile: PropTypes.func,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  profileLinkTemplate: getProfileLinkTemplate(state),
  navigateToProfile: actions.navigateToProfile,
});

export default forMembersArea(connect(mapRuntimeToProps)(forUserPrivacyType(ProfileLink)), NoopLink);
